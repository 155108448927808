<template>
	<div id="Privacy">
		<div class="Privacy-box">
			<div class="Title">
				<p>隐私政策</p>
				<div class="Title-lower"></div>
			</div>
			<div class="out">
				<div class="title">985csgo.com隐私政策</div>
				<p>
					985csgo平台以下统称“985csgo”之用户（以下统称“用户”或“您”）的隐私。您在使用985csgo服务时，我们(南京娱贵互联网有限公司)可能会收集和使用您的相关信息。 本《隐私政策》适用于我们提供的一切985csgo服务。当您使用我们任何单项服务时，您同意接受本《隐私政策》以及我们在该单项服务中发出的特定隐私信息类政策条款（下列称“特定条款”）的保护，在此情况下特定条款与本政策条款同时对您产生效力。 请注意我们不时地会检查我们的政策，因此有关的措施会随之变化。我们恳请您定期光顾本页面，以确保对我们《隐私政策》最新版本始终保持了解。在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请与985csgo客服联系。 您使用或继续使用985csgo服务，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。
				</p>
				<!-- <p>
					您使用或繼續使用985csgo.com服務，都表示您同意我們按照本《隱私政策》收集、使用、儲存和分享您的資訊
				</p> -->

				<div class="title">一、我们可能收集的信息</div>

				<p>
					（一）与个人身份无关的信息：
				</p>
				<p>当您使用985csgo服务时，我们可能收集和汇总诸如用户的来源途径、访问顺序等信息，例如记录使用985csgo服务的每个用户的来源途径、浏览器软件等。</p>
				<p>（二）有关个人身份的信息：</p>
				<p>
					当您使用985csgo服务时，我们可能收集和汇总或要求您提供有关个人身份的信息，例如个人：生日、籍贯、性别、兴趣爱好、个人电话号码、（包括系统账号、IP地址、电子邮箱地址等）；个人财产信息（交易记录、余额、优惠券、游戏类兑换码等）；通讯信息；个人上网记录和日志信息（包括网站浏览记录、软件使用记录、点击记录、操作日志等）；设备信息（包括设备型号、设备MAC地址、操作系统类型、设备设置）；软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）；位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）。
我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用985csgo服务。985csgo的目标是向所有的互联网用户提供安全、刺激、有趣及有教益的上网经历。而这些信息有助于我们实现这一目标。
				</p>

				<div class="title">二、我们如何收集和使用信息</div>
				<p>
					（一）我们将通过以下途径收集和获得您的信息：
				</p>
				<p>1、您提供的信息。 例如：</p>
				<p>（1）您在注册985csgo服务的帐号或使用985csgo服务时，向我们提供的信息；</p>

				<p>
					（2）您通过985csgo服务向其他方提供的共享信息，以及您使用985csgo服务时所储存的信息。
				</p>
				<p>2、我们获取的您的信息。您在使用985csgo服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息等。</p>
				<p>
					（二）COOKIES、日志档案和WEB BEACON
				</p>
				<p>我们或我们的第三方合作伙伴可能通过COOKIES和WEB BEACON获取和使用您的信息，并将该等信息储存为日志信息。
			通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得985csgo虚拟社区的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录985csgo的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在985csgo特定服务上花费的时间和您所选择的985csgo服务。</p>

				<div class="title">三、我们可能分享、转让或披露的信息</div>
				<p>（一）分享</p>
				<p>除以下情形外，未经您同意，我们不会与985csgo之外的任何第三方分享您的信息：</p>
				<p>1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；</p>
				<p>1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；</p>
				<p>3、实现本《隐私政策》第二条“我们如何收集和使用信息”部分所述目的；</p>
				<p>4、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；</p>
				<p>5、向委托我们进行推广的合作伙伴等第三方共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名电话号码或电子邮箱；或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</p>
				<p>6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他985csgo用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。</p>
				<p>（二）转让</p>
				<p>1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
				<p>2、在获得您的明确同意后，我们会向其他方转让您的信息。</p>
				<p>（三）披露</p>
				<p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：</p>
				<p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</p>
				<p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
				<div  class="title">四、我们如何保留、储存和保护信息</div>
				<p>我们将采取以下手段保护您的信息：</p>
				<p>（一）数据安全技术措施</p>
				<p>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。</p>
				<p>（二）我们为保护信息采取的其他安全措施</p>
				<p>我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。</p>
				<p>我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
				<p>加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。</p>
				<p>（三）我们仅允许有必要知晓这些信息的985csgo员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与985csgo的合作关系。</p>
				<p>（四）我们会采取一切合理可行的措施，确保未收集无关的信息。</p>
				<p>（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。</p>
				<p>（六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
				<p>（七）安全事件处置</p>
				<p>在通过985csgo服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。</p>
				<p>为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</p>
				<p>在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。</p>
				<p>请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入985csgo服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</p>

			</div>
		</div>
	</div>
</template>

<script>
export default {
  
  created() {
    window.scrollTo(0,0)
  },
};
</script>

<style lang="scss">

	.Privacy-box {
        .Title{
            p{
                font-size: 0.8rem !important;
            }
        }
		width: 10rem;
		margin: 0 auto;
		padding-top: 0.4rem;

		.out {
			width: 100%;

			.title {
				font-size: 0.5rem;
				color: #73d171;
				height: 1.5rem;
				line-height: 1.5rem;
			}

			p {
				font-size: 0.4rem;
				color: #fff;
				line-height: 0.8rem;
			}
		}
	}
</style>
